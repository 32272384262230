<script setup>

const props = defineProps({
  catalogPrice: [Number, String],
  price: [Number, String],
  consumerPrice: [Number, String],
  installment: [Number, String],
})
const leasing = useLeasingParams();
const client = computed(() => leasing.refParams.value.client);
</script>

<template>
  <div class="group flex justify-between">
    <div class="flex flex-col md:items-end xl:items-start">
      <div v-if="catalogPrice" class="text-xs leading-4 line-through font-extralight">
          <EffectPrice :value="catalogPrice" /> zł/<EffectNetGrossIndicator />
      </div>
      <div v-else class="text-xs leading-4 font-extralight">Cena samochodu <EffectNetGrossIndicator /></div>
      <div><span class="text-4xl">
        <EffectPrice :value="client === 'consumer' ? consumerPrice : price" /></span> 
        <span class="font-extralight">zł</span>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <div class="text-xs leading-4 font-extralight">Twoja rata <EffectNetGrossIndicator />/mies.</div>
      <div class="text-azure">
        <EffectOdomoeter class="text-4xl" :value="installment"/> <span class="font-extralight">zł</span>
      </div>
    </div>
  </div>
</template>
